<template>
    <div>
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div v-else class="card container special mt-5">
        <div class="row justify-content-center">
          <div class="col">
            <router-view></router-view>
          </div>
          <div class="card-title">
            <h1>Product Details</h1>
          </div>
  
          <div v-if="selectedProduct.videoId" class="row">
          <iframe
            class="col-12 col-sm-offset-3"
            width="720"
            height="576"
            :src="'https://www.youtube.com/embed/' + selectedProduct.videoId"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
          </div>
  
          <div
            class="col-sm-6 col-md-4 m-1"
            v-for="image in bunnyImageUrls"
            :key="image"
          >
            <img :src="image" alt="" />
          </div>
        </div>
  
        <div class="row">
          <div class="col-sm-6 col-md-4 py-0">
            <div class="card-body">
              <p>
                Owner: {{ ownerName }}
              </p>
              <p>
                ownerPhoneNumber: {{ ownerPhoneNumber }}
              </p>
              <p class="card-title">
                <span class="fst-itali">{{ location }}, Kampala</span>
              </p>
              <p class="card-text">
                <span class="fw-normal">{{ description }}</span>
              </p>
              <p>
                <strong>{{ price }} UGX</strong> / month
              </p>
              <p>
                Contact us on
  
                <strong>
                  <i class="fa-brands fa-whatsapp"></i>
                  <a href="https://wa.me/+256751568755/?text=Hello"
                    >+256 751568755</a
                  >
                  <br /><a href="tel: +256788380658">+256 788380658</a>
                </strong>
              </p>
              <router-link v-if="!route" :to="BuyLink" class="btn btn-primary"
                >Buy</router-link
              >
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // props: ["id"],
    data() {
      return {
        isLoading: false,
      };
    },
    computed: {
      price() {
        return this.selectedProduct.price;
      },
      bunnyImageUrls() {
        return this.selectedProduct.bunnyImageUrls;
      },
      location() {
        return this.selectedProduct.location;
      },
      description() {
        return this.selectedProduct.description;
      },
      ownerName() {
        return this.selectedProduct.ownerName
      },
      ownerPhoneNumber() {
        return this.selectedProduct.ownerPhoneNumber
      },
      BuyLink() {
        return this.$route.path + "/buy";
      },
      route() {
        const checkroute = this.$route.path.slice(-7);
        // console.log(checkroute);
        return checkroute == "contact";
      },
    },
    async created() {
      const productId = this.$route.params;
      // console.log("this is it", rentId.id, "this is the id");
      
      this.selectedProduct = this.$store.getters["marketplace/getProducts"].find(
        (product) => product.id === productId.id
      );
      console.log('the selected Product', this.selectedProduct)
    },
  };
  </script>
  
  <style scoped>
  div .imagePart {
    width: 100%;
    height: 20rem;
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .special {
    margin-top: 3rem;
  }
  span {
    font-weight: bold;
  }
  #email {
    font-weight: bold;
  }
  </style>